import { Row, Col, Badge } from 'react-bootstrap'
import './contact.css'
import { views } from './views'

const MailsList = ({ type, mails, setActiveMail, height }) => {
    
    let sorted = mails.sort((a,b) => (a.id < b.id) && (1 || -1))
  
    return <div style={{ maxHeight: height, overflowY: "auto" }}>
        <h3><i style={{color: "lightgray" }}>{mails.length === 0 ? `NO ITEMS IN "${type.toUpperCase()}"` : type.toUpperCase()}</i></h3>
        {
           sorted.map((mail, index) => (         
                <Row key={index} className={"inboxItem pt-4 pb-4 mb-3 ml-1 mr-1"} onClick={() => setActiveMail(mail)}>
                    <Col style={{borderRight: "1px dotted gray"}} xs={4} className={"text-break"}>{!mail.viewed && type === views.INBOX && <Badge style={{color: "green"}}>&#x25cf;</Badge>}{mail.sender}</Col>
                    <Col style={{borderRight: "1px dotted gray"}}xs={5} className={"text-break"}>{mail.subject}</Col>
                    <Col xs={3} className={"text-break"}>{mail.dateTime}</Col>
                </Row>))
        }
    </div>
}
export default MailsList