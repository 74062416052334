import { XLg } from 'react-bootstrap-icons';
import './viewer.css'

export const Header = ({vumail, icon, close, secondLevel, parent}) => {
    let classLabel = `${secondLevel ? "hak-modal-header-label-secondlevel" : "hak-modal-header-label" }`
    return (
        <div className={vumail ? "hak-modal-header-vumail" : "hak-modal-header"}>
            <div>
                <div style={{width: "100%", float: "left", textAlign:"center"}} className={classLabel}>{icon.label}</div>
                {close && <div style={{width: "auto", float: "right", marginRight: vumail? "5px": "5px", cursor: "pointer", position: "absolute", top:"0", right: "0", zIndex: "10"}}><XLg onClick={()=>close(parent)}  /></div>}
            </div>
        </div >)
}