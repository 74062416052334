import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Divider from './common/Divider';
import './css/whoweare.css'

import config from './assets/config';
const { base_url, home: { bg_fleurdelis_blue }, whoweare: { photo_nolakidsartfoundation } } = config;

const members = [{
    name: "Rueben X. Ruebens", designation: "(actor & activist, former reigning king of Krewe Babalu)"
}, {
    name: "Jamaica Rae Ishikawa", designation: "(composer & musician, former Artist-in-Residence for the Elbert Cho Memorial Fellowship)"
}, {
    name: "Celestine Broussard", designation: "(painter & activist, founder & Captain of the Mystic Krewe of Marie Laveau)"
}, {
    name: "Raheem Luanda", designation: "(founder & CEO of Bywater Capoeira do Salvador)"
}, {
}];

const WhoWeAre = (props) => {
    return (<Container fluid className={"whoweare_container"} style={{ backgroundImage: `url(${base_url + bg_fleurdelis_blue})` }}>
        <Row className="whoweare_title rosella_solid">
            <Col xs={10}>
                History of the Three Kings Ball
            </Col>
        </Row>
        <Divider xs={6} />
        <Row className="whoweare_statement">
            <Col xs={10}>
                Since 2018, the Three Kings Ball has been a cultural force and a force of
                culture. Always believing in the power of Carnival to raise changes for
                those that participate, the Three Kings provides a platform for members
                of all krewes to gather in harmony as a single community.
                <br />
                <br />
                This year’s beneficiary of our fundraising will be (as always)
                the NOLA KidsArt Foundation.
            </Col>
        </Row>
        <Divider xs={6} />
        <Row className="whoweare_title py-2 rosella_solid" >
            <Col xs={10}>
                NOLA KidsArt Board of Directors
            </Col>
        </Row>

        <Row className="whoweare_foundation" >
            <Col xs={10} lg={5}>
                <img alt={'Who We Are Foundation'} className={"whoweare_foundation_image"} src={base_url + photo_nolakidsartfoundation} />
            </Col>
            <Col xs={8} lg={5} className={"whoweare_foundation_members"}>
                {members.map((member, index) => <div className={"member"} key={"member_" + index}>
                    <span className={"name"}>{member.name}</span>
                    <span className={"designation"}>{member.designation}</span>
                </div>)}
            </Col>
        </Row>

    </Container>)
}

export default WhoWeAre;