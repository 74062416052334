import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Divider from './common/Divider';
import './css/news.css'

const news = [
    {
        type: "CONFIRMED", content: `This Year’s Three Kings Ball to Benefit the NOLA KidsArt 
    Foundation will take place on January 6 at the Clem Duggins House.` },
    {
        type: "CONFIRMED", content: `Catering for this year’s Ball will be lovingly provided by none
    other than Chef Geraldine Watkins-Blanc of Bistrot AVEC NOUS! We all
    watched our hometown culinary superstar rise to global stardom on the
    streaming series THE CHEF’S APRON, where she brought Cajun and Creole
    ingredients and flavors into the strange new world of Molecular Gastronomy!
    Now, she’s back in town from a six-month stint in Kyoto, Japan with a whole
    new spin on the crawdad. Can’t wait? Us neither! Buy your tickets today!` },
    {
        type: "UPDATE", content: `Due to unforeseen circumstances, the Krewe of Versailles will not be
        participating in this year’s Three Kings Ball.` },
    {
        type: "PRESS RELEASE", content: `New Orleans’ own superhero The Craw Daddy has been
        outspoken about “shameless behavior on the streets of his [sic] city,” but we
        have just learned that the Craw Daddy has requested he protect our fair
        Three Kings Ball from the “dastardly dangers of drunkenness and
        debauchery” in person this year. The NOLA Police Department has graciously
        donated the cost of Mr. Craw Daddy’s Ball ticket so that he may keep us all
        safe during our celebrations. Bless you, Craw Daddy!` },
];

const News = (props) => {
    return (<Container fluid className={"news_container"} >
        <Row className="news_title rosella_solid">
            <Col xs={10} > Here are some of the Three Kings Ball’s 
                latest announcements!
            </Col>
        </Row>
        {news?.length > 0 && news.map((item, index) => {
            return (<React.Fragment key={"news_item_" + index}>
                <Divider xs={6}/>
                <Row className="news_statement">
                    <Col xs={10}>
                        <span className={"news_type"}>{item?.type}:</span> {item?.content}
                    </Col>
                </Row>
            </React.Fragment>)
        })}
    </Container>)
}

export default News;