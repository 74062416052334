import React from 'react';
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css';
import App from './App';
import ThreeKings from './components/websites/threeKings';
import VuMailDrive from './components/websites/vuMailDrive';
import AnimalShelter from './components/websites/animalShelter';
import { GameStateProvider } from './context/provider';

const routing = (
	<Router>
		<Switch>
			<Route exact path="/" component={App} />
			<Route exact path="/d722223b"> <GameStateProvider ><ThreeKings hideClose={true}/></GameStateProvider></Route>
			<Route exact path="/e1e72a5e"> <GameStateProvider><AnimalShelter unlocked={false} hideClose={true}/></GameStateProvider></Route>
			<Route exact path="/e1e72a5eunlocked"> <GameStateProvider><AnimalShelter unlocked={true} hideClose={true}/></GameStateProvider></Route>
			<Route exact path="/9da9858b"> <GameStateProvider><VuMailDrive hideClose={true}/></GameStateProvider></Route>
		</Switch>
	</Router>
)

ReactDOM.render(routing, document.getElementById('root'))