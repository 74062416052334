import { GameStateContext } from './context';

export function GameStateProvider(props) {

    const local_key = "hak11"

    const getState = () => {
        let val = localStorage.getItem(local_key);
        if(val && JSON.parse(val)){
            return JSON.parse(val)
        }
    }

    const updateState = (state) => {
        localStorage.setItem(local_key, JSON.stringify(state));   
    }

    const updateStateCtaOnly = (cta) => {
        let val = localStorage.getItem(local_key);
        if(val){
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                cta: cta
            }));    
        }
    }

    const updateStateChatOnly = (chat) => {
        let val = localStorage.getItem(local_key);
        if(val){
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                chat: chat
            }));    
        }
    }

    const setEpisodeEnd = () => {
        let val = localStorage.getItem(local_key);
        if(val){
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                episodeEnd: true
            }));    
        }
    }

    const updateStateGoodFencesChatOnly = (goodFencesChat) => {
        let val = localStorage.getItem(local_key);
        if(val){
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                goodFencesChat: goodFencesChat
            }));    
        }
    }    

    const saveAnimalShelterLoginStatus = (status = false) => {
        let val = localStorage.getItem(local_key);
        if (val) {
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                animalShelterLoggedIn: status
            }));
        }
    }

    const saveVuMailLoginStatus = (status = false) => {
        let val = localStorage.getItem(local_key);
        if (val) {
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                vuMailLoggedIn: status
            }));
        }
    }

    const saveEp6GoodGuyUnlock = (status = false) => {
        let val = localStorage.getItem(local_key);
        if (val) {
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                ep6GoodGuyUnlock: status
            }));
        }
    }

    

    const saveEp5LockedVideo = (status = false) => {
        let val = localStorage.getItem(local_key);
        if (val) {
            let prevState = JSON.parse(val)
            localStorage.setItem(local_key, JSON.stringify({
                ...prevState,
                ep5LockedVideo: status
            }));
        }
    }

    return (
        <GameStateContext.Provider
            value={{
                getState: () => getState(),
                updateState: (newState) => updateState({...newState}),
                resetState: () => updateState(null),
                setEpisodeEnd: () => setEpisodeEnd(),
                updateStateCtaOnly: (cta) => updateStateCtaOnly(cta),
                updateStateChatOnly: (chat) => updateStateChatOnly(chat),
                updateStateGoodFencesChatOnly: (goodFencesChat) => updateStateGoodFencesChatOnly(goodFencesChat),
                saveVuMailLoginStatus: (status) => saveVuMailLoginStatus(status),
                saveAnimalShelterLoginStatus: (status) => saveAnimalShelterLoginStatus(status),          
                saveEp6GoodGuyUnlock: (status) => saveEp6GoodGuyUnlock(status),
                saveEp5LockedVideo
            }}
        >
            {props.children}
        </GameStateContext.Provider>
    );
}