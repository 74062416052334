import React, { Component } from 'react'
import './dock.css'

export default class Dock extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
          time: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        };
    }

	componentDidMount() {
        this.intervalID = setInterval(
          () => this.tick(),
          1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.setState({
            time: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        });
    }

    render() {
        return(<div className="dock-container">
            <div><a href="/" onClick={this.props.logout} >LOG OUT</a></div>
            <p className="app-clock">{this.state.time}</p>
        </div>)
	}
}