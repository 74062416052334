export const mailAttachments = {
    1.1 : [{ type: "image", label: "Photo of Sticky Note", url: "https://cdn.huntakiller.com/huntakiller/s11/media/sticky_note.jpg" }], 
    1.2 : [{ type: "image", label: "Photo of Celestine's Desktop", url: "https://cdn.huntakiller.com/huntakiller/s11/media/celestine_desktop.jpg" }], 
    2.2 : [{ type: "image", label: "Conjure Shop Photo", url: "https://cdn.huntakiller.com/huntakiller/s11/media/conjure_shop.jpg" }],
    3.1 : [{ type: "image", label: "Parade Good Fences Post", url: "https://cdn.huntakiller.com/huntakiller/s11/media/parade_good_fences.jpg" }], 
    5.1 : [{ type: "video", label: "Locked Video", url: "https://embed-ssl.wistia.com/deliveries/5173cf16ab3ae109a3fcccfa415eba94.mp4" }], 
    5.2 : [{ type: "image", label: "Photo of Warehouse", url: "https://cdn.huntakiller.com/huntakiller/s11/media/warehouse.jpg" }], 
    6.1 : [ { type: "image", label: "Photo of Celestine", url: "https://cdn.huntakiller.com/huntakiller/s11/media/celestine.jpg" },
            { type: "video", label: "Arrest Video", url: "https://embed-ssl.wistia.com/deliveries/a505caa00f98616de320d52a024681fe.mp4" } ] 
}
export const icons = [
    {
        label: "Spotify Playlist",
        type: "external_link",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-playlist-icon.svg",
        fullScreen: false,
        unlockAt: 1.1,
        url: "https://open.spotify.com/playlist/6cUaYGy6qcmtCdpyTiuRwS?si=8f76543d66e14aeb&nd=1"
    },
    {
        label: "Good Fences",
        type: "folder",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-goodfences-icon.svg",
        fullScreen: false,
        unlockAt: 1.1,
        children: [
            {
                label: "Good Fences Posts",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-pdf-posts-icon.svg",
                fullScreen: false,
                unlockAt: 1.1,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-1.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-2.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-3.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-4.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-5.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-6.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-7.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-11.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-9.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-10.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_1-8.jpg"
                    
                ],
                goodFences: [1,2]
            },
            {
                label: "Good Fences Posts 2",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-pdf-posts2-icon.svg",
                fullScreen: false,
                unlockAt: 3.1,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-1.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-2.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-3.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-4.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-6.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-7.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-8.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_2-5.jpg",],
                goodFences: [3,4,5]
            },
            {
                label: "Good Fences Posts 3",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-pdf-posts3-icon.svg",
                fullScreen: false,
                unlockAt: 6.1,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_3-1.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_3-3.jpg",
                    "https://cdn.huntakiller.com/huntakiller/s11/media/good_fences_post_3-2.jpg"],
                goodFences: [6]
            },
        ]
    },
    {
        label: "Documentary Trailer",
        type: "video",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-doctrailer-icon.svg",
        fullScreen: false,
        unlockAt: 1.1,
        url: "https://embed-ssl.wistia.com/deliveries/b8569295253684e3dccc0fa7385eff57.mp4"
    },
    {
        label: "Email Attachments",
        type: "folder",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/email-icon-folder.svg",
        fullScreen: false,
        unlockAt: 1.2,
        children: [
            {
                label: "Photo of Sticky Note",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/email-icon-sticky-note.svg",
                fullScreen: false,
                unlockAt: 1.2,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/sticky_note.jpg"],     
            },
            {
                label: "Photo of Celestine's Desktop",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/celestine_desktop.svg",
                fullScreen: false,
                unlockAt: 1.3,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/celestine_desktop.jpg"],     
            },
            {
                label: "Conjure Shop Photo",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/email-icon-conjure-shop.svg",
                fullScreen: false,
                unlockAt: 2.3,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/conjure_shop.jpg"],     
            },
            {
                label: "Parade Good Fences Post",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/email-icon-parade-good-fences.svg",
                fullScreen: false,
                unlockAt: 3.2,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/parade_good_fences.jpg"],     
            },
            {
                label: "Locked Video",
                type: "video",
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/email-icon-locked-video.svg",
                fullScreen: false,
                unlockAt: 5.2,
                url: "https://embed-ssl.wistia.com/deliveries/5173cf16ab3ae109a3fcccfa415eba94.mp4"
            },
            {
                label: "Photo of Warehouse",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/email-icon-warehouse.svg",
                fullScreen: false,
                unlockAt: 5.3,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/warehouse.jpg"],     
            },
            {
                label: "Photo of Celestine",
                type: "pdf",
                child: true,
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/celestine.svg",
                fullScreen: false,
                unlockAt: 6.2,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/celestine.jpg"],     
            },
            {
                label: "Arrest Video",
                type: "video",
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/arrest_video.svg",
                fullScreen: false,
                unlockAt: 6.2,
                url: "https://embed-ssl.wistia.com/deliveries/a505caa00f98616de320d52a024681fe.mp4"
            },
            {
                label: "Pawn Shop Photo",
                type: "pdf",
                img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/pawn_shop.svg",
                fullScreen: false,
                url: ["https://cdn.huntakiller.com/huntakiller/s11/media/the_pawn_shop.jpg"],
                unlockAt: 100   //this is unlocked on conditions of good guy ep6
            },
        ]
    },
    {
        label: "Crime Blotter",
        type: "pdf",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-crimeblotter-icon.svg",
        fullScreen: false,
        unlockAt: 2.1, //2.1
        top: true,
        url: ["https://cdn.huntakiller.com/huntakiller/s11/media/crime_blotter_1.jpg",
            "https://cdn.huntakiller.com/huntakiller/s11/media/crime_blotter_2.jpg"]
    },
    {
        label: "VuMail Drive",
        type: "web_vumail_drive",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-vumaildrive-icon.svg",
        fullScreen: true,
        unlockAt: 2.1 //2.1
    },
    {
        label: "Social Media Screenshots",
        type: "pdf",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-socialmediascreenshots-icon.svg",
        fullScreen: false,
        url: ["https://cdn.huntakiller.com/huntakiller/s11/media/social_media_screenshots_1.jpg",
            "https://cdn.huntakiller.com/huntakiller/s11/media/social_media_screenshots_2.jpg",
            "https://cdn.huntakiller.com/huntakiller/s11/media/social_media_screenshots_3.jpg",
            "https://cdn.huntakiller.com/huntakiller/s11/media/social_media_screenshots_4.jpg",
            "https://cdn.huntakiller.com/huntakiller/s11/media/social_media_screenshots_5.jpg"],
        top: true,
        unlockAt: 3.1 //3.1
        
    },
    {
        label: "Text From Ines",
        type: "pdf",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-textfromines-icon.svg",
        fullScreen: false,
        url: ["https://cdn.huntakiller.com/huntakiller/s11/media/text_from_ines_1.jpg",
            "https://cdn.huntakiller.com/huntakiller/s11/media/text_from_ines_2.jpg",
            "https://cdn.huntakiller.com/huntakiller/s11/media/text_from_ines_3.jpg"],
        top: true,
        unlockAt: 3.1 //3.1
    },
    {
        label: "Mason & Dillon Photo",
        type: "pdf",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-masonanddillonphoto-icon.svg",
        fullScreen: false,
        url: ["https://cdn.huntakiller.com/huntakiller/s11/media/mason_dillon_photo.jpg"],
        top: true,
        unlockAt: 4.1 //4.1
    },
    {
        label: "Mason & Dillon Recording 1",
        type: "audio",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-masonanddillonrecording-icon.svg",
        fullScreen: false,
        url: "https://cdn.huntakiller.com/huntakiller/s11/media/mason_dillon_recording_1.mp3",
        unlockAt: 4.1 //4.1
    },
    {
        label: "Text To Ines",
        type: "pdf",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-texttoinesfromcel-icon.svg",
        fullScreen: false,
        url: ["https://cdn.huntakiller.com/huntakiller/s11/media/text_to_ines.jpg"],
        top: true,
        unlockAt: 5.1 //3.1
    },
    {
        label: "Three Kings Website",
        type: "web_three_kings",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-threekingsballwebsite-icon.svg",
        fullScreen: true,
        unlockAt: 5.1
    },
    {
        label: "Animal Shelter Website",
        type: "web_animal_shelter",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-animalshelterwebsite-icon.svg",
        fullScreen: true,
        unlockAt: 6.1
    },
    {
        label: "Mason & Dillon Recording 2",
        type: "audio",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-masonanddillonrecording2-icon.svg",
        fullScreen: false,
        url: "https://cdn.huntakiller.com/huntakiller/s11/media/mason_dillon_recording_2.mp3",
        unlockAt: 6.1 //4.1
    },
    {
        label: "Raw Documentary Footage",
        type: "video",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-rawfootage-icon.svg",
        fullScreen: false,
        unlockAt: 6.1,
        url: "https://embed-ssl.wistia.com/deliveries/158a04d0535822efb0b19e5d12b2660b.mp4"
    },
]

export const mailNoMatch = { 
    1.1 : "I’m not sure what you mean. Can you take a look at everything I sent and try again? Send me another email when you think you’ve got it.",
    1.2 : "Sorry, that didn’t work… Could you try again? If she really meant for me to find this, could it have something to do with that book she gave me? When you know what the password might be, just shoot me another email. I’ll be right here!",
    2.1 : "Sorry, unfortunately that didn’t work… Clearly Celestine was working on a lot more pieces than I thought before she died. I’m really not sure what would be her latest subject out of everything I sent you, but maybe her artist statement could help?",
    2.2 : "I don’t think I understand. Take another look at her VuMail Drive and see if there are any clues that will tell us where she went. If you find something, let me know.",
    3.1 : "Hmm. I don’t think that’s correct. Did you look at the textbook page I sent you? Maybe there’s something in there we can use. If you find anything, let me know as soon as possible, okay?",
    3.2 : "That doesn’t sound right to me. Remember, we’re looking for a suspect we can rule out. It can be anyone that you know couldn’t have made it to the cemetery. When you figure out who it is, go ahead and send me another email.",
    4.1 : "Are you sure there’s not a suspect we can rule out? We’re looking for one of these people: Cyril, Mason, Dillon, Nonah, or Ines. Once you have an answer, let me know!",
    4.2 : "I don’t think that’s it. I’m so sorry. I wish I could help more, but there has to be something we’re overlooking from the murder. Keep looking! Email me when you have another idea.",
    5.1 : "I’m not quite sure what you mean. We’re looking for the name of the person who won the violet absinthe. When you know the name of that person, send me another email. You’ve got this!",
    5.2 : "I’m not sure I understand. Were you unable to unlock Ines’s video to get a sense of the timeline? That seems like a good place to start. When you figure out which suspect couldn't have stolen the bottle, let me know!",
    6.1 : "I think we got our wires crossed somehow. Do you have any idea who the killer might be? When you know who it is, send me another email. I’m counting on you!",
}



const mailConfig_1_2 = `Good Day to all my Deep Diverz & Gold Minerz!

You’re welcome! “For what?” You got the nerve to ask me that? For these sick tipz I’m about to share with you knuckleheadz!

Number One!

It’s not like on TV! Pawn shopz, flea marketz, antique mallz, - WHOEVER - will definitely sell you fake stuff. DO YOUR RESEARCH. The better the deal, the more you gotta get in there with your magnifying glass.

Number Two!

Bring a magnifying glass! (See #1) You might think you look stupid, but you look a lot stupider buying a $900 FOLEX. Know what to look for and look close! 

Number Three!

Confidence is key! Don’t know the difference between a fake and the real thing? Act like you do! Besidez, looking like you’re about to walk away getz ‘em every time. Suckerz act like suckerz. Winnerz act like winnerz. The end.

GOOD FINDZ' BEST FINDZ! 
`

const mailConfigAttachment_1_2 = [{ type: "image", label: "Historical Gas Mask", url: "https://cdn.huntakiller.com/huntakiller/s11/media/historical_gas_mask.jpg", description: "Picked this up in West Monroe. USED Gas Mask - dates back to 1917, still smells like mustard, and is yours for $120 US!" },
{ type: "image", label: "Antique Mechanical Baby Toy", url: "https://cdn.huntakiller.com/huntakiller/s11/media/antique_mechanical_baby_toy.jpg", description: `Struck gold in Natchitoches. This little guy is spooky, but some creepz love these old toyz. If you’re one of those creepz and you’ve got $450, he’s all yours!

Still haven’t figured out the subscribing thing, but I’ll let you all know in the usual placez when the next issue is ready! Now get digging!
` }]

const mailConfig_3_4_5 = `Hey GoodFinderz,

Your ol pal and mentor has had some difficult timez this past month, so sorry if this one’s a little light. I know you’re all counting on these tipz though, so here’s a few more nuggetz o’ wizdom!

Number One!

Go where the money is! Rich people got good stuff stashed away and half the time don’t even remember. Look for areaz with a lot of old rich people and go to their garage salez and estate auctionz. You’ll thank me!

Number Two!

Do your research! Every state and city in this country has a long list of legendary old stuff that’s out there somewhere, probably sitting upside down in some goofball’z basement. Knowing what to look out for is what separates the Finderz from the Seekerz. If you’re a NOLA local like myself, read up on local lore like the old nailz from the windows of the Ursuline Convent, the horseshoez hammered by Pierre Lafitte, and the last remaining bottle of Mère Petitpas’s purple potion. There’ve been scorez of scammerz over the yearz, but every now and then the genuine articlez make a pro like you or me a millionaire.

Number Three!

Don’t be afraid to say “no deal!” If the deal’s no good, don’t take it! You lose more buying a bad flip than walking away with what you already got in your pocketz.

GOOD FINDZ’ BEST FINDZ!
`

const mailConfigAttachment_3_4_5 = [{ type: "image", label: "Antique Alligator Skin Boots", url: "https://cdn.huntakiller.com/huntakiller/s11/media/antique_alligator_skin_boots.jpg", description: `Genuine dino-skin bootz by way of a secondhand store in Slidell. Great shape and already broken in! This fine bit of craftsmanship can be yourz for $800.` },
{ type: "image", label: "Antique Zydeco Button Accordion", url: "https://cdn.huntakiller.com/huntakiller/s11/media/antique_zydeco_button_accordion.jpg", description: `Sometimez going off the beaten path getz you a gem. This Bogalusa beauty’z a 1952 Cajun accordion from the master himself, Sidney Brown. This is as rare as findz get, and is priced at $2,500. I can’t stand the sound of it, but I’m looking forward to hearing ‘cha-ching’ when it sellz! Won’t fetch as good a price as the squeezebox belonging to ol’ Zeppo La-La-La (that Tous les Bouffonz clown), but it’ll do.` }]

export const mailConfig = {
    toFrom: "Sylvia Jones (sylviainks@vumail.io)",
    1.1: "STUDIO",
    1.2: "DECODED",
    2.1: "DRIVE",
    2.2: "EMPORIUM",
    3.1: "TIMELINE",
    3.2: "CLEARED",
    4.1: "INNOCENT",
    4.2: "EVIDENCE",
    5.1: "WINNER",
    5.2: "CLEARED",
    6.1: "SOLVED",
    goodFences: {
        1: {
            toFrom: "goodfindzguy59@vumail.io",
            toSubject: "Newzletter",
            toBody: "Send me your latest newzletter. Thankz!",
            fromSubject: "Good Findz’ Good Newz: Issue 31",
            fromBody: mailConfig_1_2,
            attachments: mailConfigAttachment_1_2
        },
        2: {
            toFrom: "goodfindzguy59@vumail.io",
            toSubject: "Newzletter",
            toBody: "Send me your latest newzletter. Thankz!",
            fromSubject: "Good Findz’ Good Newz: Issue 31",
            fromBody: mailConfig_1_2,
            attachments: mailConfigAttachment_1_2
        },
        3: {
            toFrom: "goodfindzguy59@vumail.io",
            toSubject: "Newzletter",
            toBody: "Send me your latest newzletter. Thankz!",
            fromSubject: "Good Findz’ Good Newz: Issue 32",
            fromBody: mailConfig_3_4_5,
            attachments: mailConfigAttachment_3_4_5
        },
        4: {
            toFrom: "goodfindzguy59@vumail.io",
            toSubject: "Newzletter",
            toBody: "Send me your latest newzletter. Thankz!",
            fromSubject: "Good Findz’ Good Newz: Issue 32",
            fromBody: mailConfig_3_4_5,
            attachments: mailConfigAttachment_3_4_5
        },
        5: {
            toFrom: "goodfindzguy59@vumail.io",
            toSubject: "Newzletter",
            toBody: "Send me your latest newzletter. Thankz!",
            fromSubject: "Good Findz’ Good Newz: Issue 32",
            fromBody: mailConfig_3_4_5,
            attachments: mailConfigAttachment_3_4_5
        },
        6: {
            toFrom: "goodfindzguy59@vumail.io",
            toSubject: "Newzletter",
            toBody: "Send me your latest newzletter. Thankz!",
            fromSubject: "Good Findz’ Good Newz: Issue 33",
            fromBody: `Hey GoodFinderz,

            Welcome to the final newzletter of the ‘Broke Findz’ era. You may have seen in my Good Fencez post, but if not here’s the short version: I hit the jackpot! Found a genuine lucky artifact and now I’m rolling in dough, my wife is trying to get back with me, and they’re giving me my own tv show (Working Title: Trash Warz). 
            
            There’s only one Find this week: my lucky artifact. I’m still working to figure out exactly what it is, but so far it’s brought me nothing but fortune. This is absolutely NOT FOR SALE but I’m happy to show it off to all my loyal Finderz. It’s attached to this email, you lucky sunz-a-gunz.
            
            And for my most dedicated of admirerz, here’s a little peek at what we’ve got in the workz for my new show, coming soon to every gas pump across the country!
            
            EXCERPT FROM PITCH DOC BELOW IS PROPERTY OF THE GOODFINDZGUY. ALL THIEVEZ AND COPYWRIGHT INFRINGERZ WILL BE PROSECUTED TO THE FULLEST EXTINCT OF THE LAW.
            
            ***
            
            Trash Warz 
            
            Pilot: The Fenton Menace
            
            In this episode, Guy tears through Acadiana in search of Huey P. Long’s fabled “deduct box” and any other trash/treasure that catches his keen eye. At a pawn shop in Fenton, Guy walks viewers through his shrewd treasure-finding process and spots a Revolutionary War cannon he knows he can make big money on if he can get it for the right price. But the man behind the counter knows his stuff, and the negotiation gets heated as Guy works his magic.
            
            Pilot Takeaway:
            
            Guy reveals the Pawn Shop Secret Code: Mary Louise! The code is used by shop owners to disguise numbers in a negotiation, like the lowest offer they’re willing to take or what the item cost them from the original seller. It works by substituting a 10 letter phrase with no repeated letters for the numbers 1-9 and 0 like this:
            
            M	A	R	Y	L	O	U	I	S	E
            1	2	3	4	5	6	7	8	9	0
            
            So YOU = $4.67. Sneaky, right?
            
            But remember! Not every shop uses the words “Mary Louise.” Even so, if you’re quick on the uptake like Guy, you’ll find the code word in no time!
            
            ***
            
            Thatz all for now, Finderz! If you want to see my lucky charm, click on the photo and remember that jealousy getz you nowhere. 
            
            Guy            
            `,
            attachments: [{ type: "image", label: "Pawn Shop Photo", url: "https://cdn.huntakiller.com/huntakiller/s11/media/the_pawn_shop.jpg", description: `` }]
        }
    }
}
