import React, { useState } from 'react'
import { Header } from './ModalHeader';
import './viewer.css';
import { FormControl, Button, Row } from 'react-bootstrap';
import Draggable from 'react-draggable'

const Password = ({ setPassed, close }) => {
    const pass = process.env.REACT_APP_LOCKED_VIDEO;

    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const onChange = val => setPassword(val)
    const btnClick = event => {
        if (!password) { setError(true) }
        else {
            pass.toLowerCase() === password.toLowerCase() ? setPassed(true) : setError(true);
        }
        event.preventDefault();
    }
    const nodeRef = React.useRef(null);

    return (
        <Draggable nodeRef={nodeRef} handle=".hak-modal-header-label-secondlevel" bounds={"#folder-bounds"}>
            <div ref={nodeRef} className={"hak-modal-password"}>
                <Header icon={{ label: "PASSWORD REQUIRED!" }} close={close} secondLevel={true}/>
                

                <div className="row center">
                <Row><span style={{ "marginTop": "15px", color: 'white' }}>Your Password Hint: The ___ __ ___ is nigh (no spaces)</span></Row>
                    <form className="hak-form">
                        {error && <div className="error-message center">Invalid Response</div>}
                        <span className="center" style={{ "marginBottom": "15px" }}>
                                <FormControl
                                    type='password'
                                    name='password'
                                    defaultValue={password}
                                    onChange={event => onChange(event.target.value)}
                                    autoFocus
                                />
                        </span>
                        <span className="center">
                            <Button type="submit" onClick={btnClick} className="btn btn-dark">ENTER</Button>
                        </span>
                    </form>
                </div>
            </div>
        </Draggable>
    )
}

export default Password;