import { Row } from 'react-bootstrap'
import { views } from './views'
import './contact.css'

const LeftNav = ({ activeView , setActive }) => {

    return <div style={{overflow:"scroll"}}>
        <Row className = {"p-2 m-2"} >
            <img alt={'New Message'} style={{cursor: "pointer", width: "100%"}} onClick={() => setActive(views.NEW)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-newmessage-button.svg"} />
        </Row>
        <Row className ={"contact-left-navitem p-2 m-2"}>
            <img alt={'Inbox'} style={{cursor: "pointer" }} onClick={() => setActive(views.INBOX)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-menu-inbox-button.svg"} />
        </Row>
        <Row className = {"contact-left-navitem p-2 m-2"}>
            <img alt={'Sent'} style={{cursor: "pointer" }} onClick={() => setActive(views.SENT)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-menu-sent-button.svg"} />
        </Row>
        <Row className = {"contact-left-navitem p-2 m-2"}>
            <img alt={'Trash'} style={{cursor: "pointer" }} onClick={() => setActive(views.TRASH)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-email-menu-trash-button.svg"} />
        </Row>
    </div>
}
export default LeftNav