import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './css/thekrews.css'

import config from './assets/config';
const { base_url, thekrews: {
    thekrews_barksalot,
    thekrews_dulysse,
    thekrews_mystic,
    thekrews_piratebros,
    thekrews_touslesboufons,
    thekrews_vagabonds,
    thekrews_versailles } } = config;

const theKrews = [thekrews_mystic, thekrews_versailles, thekrews_dulysse, thekrews_piratebros, thekrews_touslesboufons, thekrews_barksalot, thekrews_vagabonds];
const TheKrews = (props) => {
    return (<Container fluid className={"thekrews_container"}>
        <Row className="thekrews_title rosella_solid">
            <Col lg={10}>
                The Krewes
            </Col>
        </Row>
        <Row className={"justify-content-center"}>
            {theKrews.map((svg_endpoint, index) => {
                return (<Col md={5} key={"image_" + index} lg={4} className={"thekrews_logo_container"} >
                    <img alt={'The Krews Logo'} src={base_url + svg_endpoint} className={"thekrews_logo"} />
                </Col>)
            })}

        </Row></Container>)
}

export default TheKrews;