import React from 'react'
import { Header } from './ModalHeader';
import './viewer.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Draggable from 'react-draggable'

const Viewer = ({ icon, close }) => {

    const nodeRef = React.useRef(null);
    return (
        <Draggable 
            nodeRef={nodeRef}
            handle={icon.child ? ".hak-modal-header-label-secondlevel" : ".hak-modal-header-label" }
            bounds={icon.child ? "#folder-bounds" : "#draggable-bounds"}>
            <div ref={nodeRef} className={"hak-modal-audio"}>
                <Header icon={icon} close={close} secondLevel={Boolean(icon.child)} />
                <AudioPlayer autoPlay src={icon.url} className="hak-audioplayer" />
            </div>
        </Draggable>
    )
}

export default Viewer;