const config = {
    base_url: window.location.origin,
    header: {
        close_icon: "/assets/animal-shelter/hak11-animalshelter-menu-closebutton.svg",
        header_logo: "/assets/animal-shelter/hak11-animalshelter-header-logo.svg",
        menu_findapet_active: "/assets/animal-shelter/hak11-animalshelter-menu-findapet-active.svg",
        menu_findapet_inactive: "/assets/animal-shelter/hak11-animalshelter-menu-findapet-inactive.svg",
        menu_home_active: "/assets/animal-shelter/hak11-animalshelter-menu-home-active.svg",
        menu_home_inactive: "/assets/animal-shelter/hak11-animalshelter-menu-home-inactive.svg",
        menu_signin_active: "/assets/animal-shelter/hak11-animalshelter-menu-signin-active.svg",
        menu_signin_inactive: "/assets/animal-shelter/hak11-animalshelter-menu-signin-inactive.svg",
        menu_hamburger: "/assets/animal-shelter/hak11-animalshelter-menu-hamburger.svg"
    },
    home: {
        home_banner: "/assets/animal-shelter/hak11-animalshelter-photo-homepage-herophoto.jpg"
    },
    findapet: {
        findapet_clementine: "/assets/animal-shelter/hak11-animalshelter-photo-findapet-clementine.jpg",
        findapet_neville: "/assets/animal-shelter/hak11-animalshelter-photo-findapet-neville.jpg",
        findapet_porter: "/assets/animal-shelter/hak11-animalshelter-photo-findapet-porter.jpg",
        findapet_ziggy: "/assets/animal-shelter/hak11-animalshelter-photo-findapet-ziggy.jpg"
    },
    signin: {
        signin_herophoto: "/assets/animal-shelter/hak11-animalshelter-photo-signin-herophoto.jpg"
    }
}

export default config