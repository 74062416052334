const config = {
    base_url: window.location.origin,
    login: {
        login_background: "/assets/vu-mail/hak11-vumail-gradiantbg.svg",
        login_logo: "/assets/vu-mail/hak11-vumail-vumaillogo.svg",
        close_blue: "/assets/vu-mail/hak11-threekingsball-menu-close-blue.svg",
        close: "/assets/animal-shelter/hak11-animalshelter-menu-closebutton.svg"
    }
}

export default config;