import React from 'react';
import { Col, Row } from 'react-bootstrap';

import config from '../assets/config';

const { base_url, common: { element_gold_divider } } = config;

const Divider = (props) => {
    return <Row className={"d-flex w-100 align-items-center flex-column my-4"}>
        <Col lg={3} {...props}>
            <img alt={'Gold Divider'} src={base_url + element_gold_divider} />
        </Col>
    </Row>
}
export default Divider;