import './vu.css'
import Image from './../../viewer/Image'
import {useState} from 'react'
import { useMediaQuery } from '../../../_shared/utils'

const Files = ({setCurrent}) => {

    const [current, setCurrentFileState] = useState("")

    const imgRoot = "https://cdn.huntakiller.com/huntakiller/";

    const imgs = {
        "athalie" : ["s11/media/athalie_1.jpg","s11/media/athalie_2.jpg","s11/media/athalie_3.jpg"],
        "jay" : ["s11/media/jay_1.jpg","s11/media/jay_2.jpg","s11/media/jay_3.jpg","s11/media/jay_4.jpg"],
        "ines" : ["s11/media/Ines_1.jpg","s11/media/Ines_2.jpg"],
        "nonah" : ["s11/media/nonah_1.jpg","s11/media/nonah_2.jpg","s11/media/nonah_3.jpg","s11/media/nonah_4.jpg","s11/media/nonah_5.jpg"],
        "zee" : ["s11/media/zee_1.jpg", "s11/media/zee_2.jpg"]
    }


    const closeCurrent = (parent) => {
        setCurrentFile("")
        setCurrent(parent)
    }

    const setCurrentFile = (current) => {
        setCurrentFileState(current)
        //imgs[current] && window?.ReactNativeWebView?.postMessage(imgs[current][0]);
    }

    let small = useMediaQuery('(max-width: 768px)')

    const getU = () => {
        return imgs[current].map(i=>(imgRoot+i))
    }
 
    return <div className="fileContainer" style={{ color: "#2E3191"}}>

        {current === "athalie" && <Image vumail={true} icon={ {vh: small ? 52: 67, url: getU(), label: "Athalie"}} close={closeCurrent} parent={"texts"}/>}
        {current === "jay" && <Image vumail={true} icon={ {vh: small ? 52: 67,url: getU(), label: "Jay"} } close={closeCurrent} parent={"texts"}/>}
        {current === "ines" && <Image vumail={true} icon={ {vh: small ? 52: 67,url: getU(), label: "Ines"} } close={closeCurrent} parent={"texts"}/>}
        {current === "nonah" && <Image vumail={true} icon={ {vh: small ? 52: 67,url: getU(), label: "Nonah"} } close={closeCurrent} parent={"texts"}/>}
        {current === "zee" && <Image vumail={true} icon={ {vh: small ? 52: 67,url: getU(), label: "Zee"} } close={closeCurrent} parent={"texts"}/>}

        <div style={{cursor: "pointer"}} className="row ml-4 mr-4 mt-3" onClick={() => setCurrent("files")}>
            <div className={"w-100"}><h3>Files {" > "} Texts</h3></div>
            <div>
                Name <img style={{ height: "2vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-arrowicon.svg"} alt={"VuMail"} />
            </div>
        </div>

        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("athalie")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-photoicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Athalie</span>
        </div>

        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("jay")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-photoicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Jay</span>
        </div>

        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("ines")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-photoicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Ines</span>
        </div>

        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("nonah")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-photoicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Nonah</span>
        </div>

        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("zee")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-photoicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Zee</span>
        </div>
    </div>
}

export default Files;

