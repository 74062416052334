import React from 'react'
import { XLg } from 'react-bootstrap-icons';
import Draggable from 'react-draggable'
import './vu.css'

const Text = ({ closeCurrent, parent }) => {

    const txtContent = 
    `ym copterrot’s mopiermu acn eb fondu lwobe. sue eht reeht steda fo eraciotn.

    1
    The ramparts have broken, drowning in waves of Bourbon I sink. And alone, fighting, gasping for air, dancing through the burning Elysian Fields, watching as St. Claude laughs at my plight. 

    2
    But now I see the King has left us to our fate, like lambs to the slaughter. His burgundy cloak singed and discarded like the rest of his creation. Then the Royal union of blood was the only thing keeping me afloat. 

    3
    May the music of the Frenchmen guide me home, for without it I shall drift into the void blind, until the congress of angels calls me before St. Peter to damn me for my faults.

    4
    What to do? What to die? Why why why
    
    5
    I know how limited my time is. I’ve always known. That’s why I dig my nails into the stone. That’s why I bleed and leave the stains. To live. To live forever and then some. Ha Ha. Good try. If it’s truly my time, fill my veins with fire. I will die screaming with my fists clenched tight.
    `

    const nodeRef = React.useRef(null);
    return (
        <Draggable
            nodeRef={nodeRef}
            handle={".hak-modal-header-label"}
            bounds={"#draggable-bounds"}>
            <div ref={nodeRef} className={"vu-txt"}>
                <div className={"vu-header"}>
                    <div>
                        <div style={{ width: "100%", float: "left", textAlign: "center" }} className={"hak-modal-header-label"}>UNTITILED.txt</div>
                        <div style={{ width: "auto", float: "right", paddingRight: "5px", cursor: "pointer", position: "absolute", top: "0", right: "0", zIndex: "10" }}><XLg onClick={() => closeCurrent(parent)} /></div>
                    </div>
                </div >
                <div style={{ whiteSpace: "pre-line", "overflowY": "auto", maxHeight: "calc(100% - 5vh)", padding: "20px", "color": "black" }} dangerouslySetInnerHTML={{ __html: txtContent }}></div>
            </div>
        </Draggable>
    )
}

export default Text;